import axios from 'axios'
//import * as Sentry from '@sentry/react'

const axiosClient = axios.create({
  timeout: 60000,
  maxRedirects: 0,
})

async function request(method, url, data) {
  try {
    return await axiosClient.request({
      method: method,
      url: url,
      data: data,
    })
  } catch (e) {
    console.error(`😱 Axios request failed: ${e}`)
    //Sentry.captureException(e)
    throw e
  }
}

export { request }
