import React from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import * as gtag from '../../lib/gtag'
import * as fbp from '../../lib/fbp'
import { request } from '../../lib/forms'
import Loader from 'react-loader-spinner'

export default function BetaContact({ title, subtitle, buttonText, thankYouText, utm, viewport, notop, url = '/' }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [mounted, setMounted] = React.useState(false);
  const [showResults, setShowResults] = React.useState(false)
  const [showForm, setShowForm] = React.useState(true)
  const [showLoading, setShowLoading] = React.useState(false)
  const router = useRouter()

  React.useEffect(() => {
    setMounted(true)
  }, [])
  
  const onSubmit = async data => {
    setShowLoading(true)
    setShowForm(false)
    utm.setConversion()
    await request(
      'POST',
      'https://internal-api.gosuac.com/api/v1/leads',
      JSON.stringify({
        email: data.email,
        language: data.lang,
        project_id: '1',
        client_id: '1',
        extra_data: {
          page_name: 'Home',
          page_url: url,
          hutk: document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1'),
        },
        projects: 'playbak',
        UTMS: utm.history || [],
      })
    )
    gtag.event({ action: 'generate_lead', category: 'engagement', label: 'form', value: 1 })
    gtag.event({ action: 'beta_lead', category: 'beta', label: 'form', value: 1 })
    gtag.adwordsLead()
    fbp.event({ event: 'Lead', data: {} })
    fbp.event({ event: 'beta_lead', data: {} })
    setShowResults(true)
    setShowLoading(false)
  }

  return mounted && (
    <div className={'w-full flex flex-wrap justify-center'}>
      {viewport === 'mobile' ? (
        <React.Fragment>
          <div className={'w-full flex flex-wrap md:mb-4 justify-center beta-contact-content'}>
            <p className={'title'}>{title}</p>
            <p className={'sub-title'}>{subtitle}</p>
            <div className={'w-full flex flex-wrap justify-center'}>
              <div className={'contact-form'}>
                <div className={'beta-diagram-img-content'}>
                  <div className={'w-full flex flex-wrap beta-diagram-img'}>
                    <Image
                      src={'https://cdn.gosuac.com/playbak/23eef851-e059-4952-9203-a5677de1362c/group-225.svg'}
                      layout={'fill'}
                      alt=""
                    />
                  </div>
                </div>
                <div className={'w-full flex flex-wrap form-content justify-center'}>
                  {showLoading && <Loader type="Grid" color="#fff" height={20} width={20} />}

                  {showResults && <p className={'thanks-txt'}>{thankYouText}</p>}

                  {showForm && (
                    <form onSubmit={handleSubmit(onSubmit)} className={'form-content w-full flex flex-wrap'}>
                      <input
                        {...register('email', {
                          required: 'Required',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'invalid email address',
                          },
                        })}
                        id={'email'}
                        name="email"
                        placeholder={router.locale === 'es' ? 'Quiero unirme a la BETA' : 'I want to join the BETA'}
                        required={'email'}
                        type={'text'}
                      />
                      <div className={'btn-send-content'}></div>
                      <button type="submit" className={'btn-send'}>
                        {buttonText}
                      </button>

                      <input
                        {...register('lang')}
                        name="lang"
                        style={{ display: 'none' }}
                        defaultValue={router.locale}
                        tabIndex="-1"
                        autoComplete="false"
                      />

                      <input
                        {...register('fax_number')}
                        id="fax_number"
                        name="fax_number"
                        placeholder="Su Numero de fax"
                        defaultValue=""
                        tabIndex="-1"
                        autoComplete="false"
                        style={{ display: 'none' }}
                      />
                      <input
                        {...register('second_name')}
                        id="second_name"
                        name="second_name"
                        placeholder="Segundo Nombre"
                        defaultValue=""
                        tabIndex="-1"
                        autoComplete="false"
                        style={{ display: 'none' }}
                      />
                    </form>
                  )}
                </div>
                <div className={'fly-send-img-content'}>
                  <div className={'w-full flex flex-wrap fly-send-img'}>
                    <Image src={'/images/fly_send.png'} layout={'fill'} alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className={`w-full flex flex-wrap justify-center beta-contact-content ${notop ? 'no-marging-top' : ''}`}>
          <div className={'w-full'}>
            <p className={'title'}>{title}</p>
            <p className={'sub-title'}>{subtitle}</p>
            <div className={'w-full flex flex-wrap justify-center'}>
              <div className={'contact-form'}>
                <div className={'beta-diagram-img-content'}>
                  <div className={'w-full flex flex-wrap beta-diagram-img'}>
                    <Image
                      src={'https://cdn.gosuac.com/playbak/23eef851-e059-4952-9203-a5677de1362c/group-225.svg'}
                      layout={'fill'}
                      alt=""
                    />
                  </div>
                </div>
                <div className={'w-full flex flex-wrap form-content justify-center'}>
                  {showLoading && <Loader type="Grid" color="#fff" height={50} width={50} />}

                  {showResults && <p className={'thanks-txt'}>{thankYouText}</p>}

                  {showForm && (
                    <form onSubmit={handleSubmit(onSubmit)} className={'form-content w-full flex flex-wrap'}>
                      <input
                        {...register('email', {
                          required: 'Required',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'invalid email address',
                          },
                        })}
                        name="email"
                        placeholder={router.locale === 'es' ? 'Quiero unirme a la BETA' : 'I want to join the BETA'}
                        required={'email'}
                        type={'text'}
                      />
                      <div className={'btn-send-content'}></div>
                      <button type="submit" className={'btn-send'}>
                        {buttonText}
                      </button>

                      <input
                        {...register('lang')}
                        name="lang"
                        style={{ display: 'none' }}
                        defaultValue={router.locale}
                        tabIndex="-1"
                        autoComplete="false"
                      />

                      <input
                        {...register('fax_number')}
                        id="fax_number"
                        name="fax_number"
                        placeholder="Su Numero de fax"
                        defaultValue=""
                        tabIndex="-1"
                        autoComplete="false"
                        style={{ display: 'none' }}
                      />
                      <input
                        {...register('second_name')}
                        id="second_name"
                        name="second_name"
                        placeholder="Segundo Nombre"
                        defaultValue=""
                        tabIndex="-1"
                        autoComplete="false"
                        style={{ display: 'none' }}
                      />
                    </form>
                  )}
                </div>
                <div className={'fly-send-img-content'}>
                  <div className={'w-full flex flex-wrap fly-send-img'}>
                    <Image src={'/images/fly_send.png'} layout={'fill'} alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
